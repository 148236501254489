import React, { Component } from 'react';
import './Footer.css';
import axios from 'axios'

export default class Footer extends Component {
    state = {
        ipv4: "127.0.0.1",
        city: "Ciudad",
        country: "Pais",
        text: "",
        author: ""
    }

    componentDidMount() {
        const obtenerData = async() => {
            const url = 'https://api.kope.cl/ipInfo';
            await axios.get( url )
                .then( (response) => {
                    this.setState({
                        ipv4: response.data.data.IPv4,
                        city: response.data.data.city,
                        country: response.data.data.country
                    });
                })
                .catch( ()=>{
                    console.log("NO funciona =(");
                });
        }
        obtenerData();
        const obtenerDataQuotes = async() => {
			const url = 'https://api.kope.cl/getQuote';
			await axios.get( url )
				.then( (response) => {
					this.setState({
						text: "\"" + response.data.data.quote_text + "\"",
						author: "-- " + response.data.data.quote_author
					});
				})
				.catch( () => {
					console.log("NO funciona =(");
				});
		}
		obtenerDataQuotes();
    }
    render() {
        return (
        <div>
            <div className="footer p-6 bg-gray-800 text-white">
                <div className="p-6">
                    {/* Columna derecha */}
                    <div className="text-center">
                        <p className="italic text-ms text-gray-400">{this.state.text}</p>
                        <p className="text-ms text-right gray-300">{this.state.author}</p>
                    </div>
                </div>
            </div>

            <div className='w-full h-2'></div>

            <div className="footer p-6 text-gray-400">
                <div className="">
                    {/* Columna izquierda */}
                    <div className="">
                        {/* <p>Desde: {this.state.ipv4} - {this.state.city}, {this.state.country}</p> */}
                        <p>kopE.cl &reg; 2006 - 2024</p>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
