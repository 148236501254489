import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import './App.css';
import Menu          from './components/Layout/Menu';
import Header        from './components/Layout/Header';
import Kuotes        from './components/Kuotes/Kuotes';
import Footer        from './components/Layout/Footer';
import Blog          from './components/Blog/Blog';
import ErrorNotFound from './components/ErrorNotFound/ErrorNotFound';
import Wishlist      from './components/Wishlist/Wishlist';
import Post          from './components/Blog/Post';
import Photos        from './components/Photos/Photos';
import Album         from './components/Photos/Album';
import Login         from './components/Auth/Login';
import Logout        from './components/Auth/Logout';
import Quotes        from './components/Quotes/Quotes';


function App() {
    return (
        <div className="App">
                <Router>
                    <Switch>
                        <Route exact path='/'>
                            <Menu />
                            <Header />
                        </Route>
                        <Route exact path='/blog'>
                            <Menu />
                            <Blog />
                        </Route>
                        <Route path="/wishlist">
                            <Menu />
                            <Wishlist />                            
                        </Route>
                        <Route exac path="/blog/post/:id">
                            <Menu />
                            <Post />
                        </Route>
                        <Route exac path="/photos">
                            <Menu />
                            <Photos />
                        </Route>
                        <Route exac path="/album/:id">
                            <Menu />
                            <Album />
                        </Route>
                        <Route exac path="/login">
                            <Menu />
                            <Login />
                        </Route>
                        <Route exac path="/logout">
                            <Logout />
                        </Route>
                        <Route exact path='/quotes'>
                            <Menu />
                            <Quotes />
                        </Route>
                        <Route path="*">
                            <Menu />
                            <ErrorNotFound />
                        </Route>
                    </Switch>
                </Router>
                <Footer />
        </div>
  );
}

export default App;
