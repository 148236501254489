import './Menu.css'

import React, { Component } from 'react';

export default class Menu extends Component {
    render (){
        function showModal() {
            document.getElementById("defaultModal").classList.remove('hidden');
            return false;
        }
        function hideModal() {
            document.getElementById("defaultModal").classList.add('hidden');
            return false;
        }
        return (
            <div className="menu">
                <div className='menu-boder'></div>
                <button-group>
                    <button
                        className="kp-button"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/';
                        }}>Inicio</button>
                        <button
                        className="kp-button"
                        onClick={showModal}>¿kopE?</button>
                        <button
                        className="kp-button"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/blog';
                        }}> Blog</button>                        <button
                        className="kp-button"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/quotes';
                        }}> Frases</button>
                        <button
                        className="kp-button"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/photos';
                        }}> Fotos</button>
                </button-group>
                {localStorage.getItem("authToken") != null && (
                    <button type="button"
                        className="kp-button"
                        onClick={(e) => {
                            e.preventDefault();
                            localStorage.setItem('authToken', null);
                            window.location.href='/logout';
                        }}>X</button>
                )}
                <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
                    <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    ¿Qué es kopE?
                                </h3>
                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal" onClick={hideModal}>
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                            </div>
                            <div className="p-6 space-y-6">
                                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 flex justify-center">
                                    <img className="rounded-full w-32" alt="" width={200} src="https://web.kope.cl/images/kp_moica.png" />
                                </p>
                                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">Eduardo D. Peña Ceballos</p>
                                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                    Papá de Renata desde 2013. Hijo de Ramón y Felisa. Nacido en Santiago de Chile en 1980. Hincha de Universidad de Chile. Bombero Pompe France Santiago. Prototipador, Programador Python, PHP, Java, ReactJS y Administrador de Sistemas Posix/Linux. Aprendiz de Carpintero y Jardinero. Aficionado al estudio de las Artes Marciales Japonesas. Estudiante Raijin Dojo (Bujinkan) y Club de Judo Eslabón.
                                </p>
                                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                    Desayuno perfecto: Barros Luco con Tomate.
                                </p>
                                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                    Donaciones BTC: bc1qe8w30mk2h8w0n48zsy69md95hd0hjjkvch7zgw
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}