import './Quotes.css'

import React, { Component } from 'react';
import axios from "axios";

export default class Quotes extends Component {

    state = {
		text: "En toda clase de entrenamiento el punto más importante es librarse de las malas costumbres.",
		author: "Jigoro Kano",
        progress: 0
	}

	constructor(props){
		super(props)
		this.intervalSeconds = 20
	}
    
	componentDidMount() {
		this.interval = setInterval(() => obtenerData(), this.intervalSeconds * 1000);

		const obtenerData = async() => {
			const url = 'https://api.kope.cl/getQuote';
			await axios.get( url )
				.then( (response) => {
					this.setState({
						text: response.data.data.quote_text,
						author: response.data.data.quote_author
					});
					this.setState({ progress: 0 }); // Reinicia la barra de progreso
				})
				.catch( () => {
					console.log("NO funciona =(");
				});
		}
		obtenerData();

		// Configura un segundo intervalo para actualizar la barra de progreso
		this.progressInterval = setInterval(() => {
			this.setState(prevState => {
				const newProgress = prevState.progress + (100 / this.intervalSeconds);
				return { progress: newProgress >= 100 ? 100 : newProgress };
			});
		}, 1000); // Incrementa el progreso cada segundo
	}

	componentWillUnmount() {
        // Limpia los intervalos al desmontarse el componente
        // clearInterval(this.quoteInterval);
        clearInterval(this.progressInterval);
    }
    

    render() {
        return (
          <div className="flex items-center justify-center min-h-screen bg-gray-70">
            <div className="bg-black bg-opacity-70 text-white shadow-xl rounded-lg p-10 max-w-lg text-center quotes-container">
              <p className="text-2xl font-italic mb-6 justify">
                "{this.state.text}"
              </p>
              <p className="text-xl font-bold text-right text-gray-500">-- {this.state.author}</p>
				{/* Barra de progreso */}
				<div className="w-full bg-gray-200 h-1 rounded mt-4">
					<div className="bg-blue-500 h-1 rounded" style={{ width: `${this.state.progress}%` }}></div>
				</div>
            </div>
          </div>
        );
      }
}
